export const scrollTo = (id) => {
    const yOffset = -50;
    const element =document.getElementById(id);
    if(element){
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }
}
export const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
}