import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {mapStateToPropsJS,mapDispatchToPropsWithActionJS, mapDispatchToPropsWithActionHTML, reduxElementJS,reduxElementHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class ReduxElement extends Component{
    state = {rightNav : [
        {
            name : 'Overview',
            id : 'overview'
        },
        {
            name : 'ReduxElement Functions and Property',
            id : 'redux-element-pre-defined-functions'
        },
        {
            name : 'Defining mapStateToProps',
            id : 'defining-mapStateToProps'
        },
        {
            name : 'Defining mapDispatchToProps',
            id : 'defining-mapDispatchToProps'
        },
        {
            name : 'Accessing the Store through props',
            id : 'accessing-the-store'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading"><code>ReduxElement</code> </h1>
                    <section className="docs-intro">
                        <p>
        The <code>ReduxElement</code> LWC component connects a LWC component to a Redux store. To make the ReduxElement, we can use <code>Redux(LightningElement)</code> method or We can also import the <code>ReduxElement</code> from the <code>c/lwcRedux</code> and use directly instead of <code>Redux(LightningElement)</code>. Using <code>Redux</code> method the base component or using <code>ReduxElement</code> are works same.
                            <br></br><br></br>
                            It provides its connected component with the pieces of the data it needs from the store, and the functions it can use to dispatch actions to the store.
                            <br></br><br></br>
                            This is component that you need to extends you LWC component to connect to redux store.
                            <br></br><br></br>
                            
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="overview">
                    <h2 className="section-heading">Overview <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'overview')} /></h2>
                    <p>ReduxElement is connector element that connect Lightning Web Component to Redux store. It's is an <code>es6</code> class that extends all the feature of the LightingElement provided by Salesforce.</p>
                    <CodePanel id="counter" htmlFileName="counter.html" jsFileName="counter.js" js={reduxElementJS} html={reduxElementHTML}></CodePanel>
                    <p className="margin-top-small">The <code>mapStateToProps</code> and <code>mapDispatchToProps</code> deals with your Redux store’s state and dispatch, respectively. state and dispatch will be supplied to your mapStateToProps or mapDispatchToProps functions as the first argument.</p>
                </section>
                
                <section className="docs-section" id="redux-element-pre-defined-functions">
                    <h2 className="section-heading"><code>ReduxElement</code> Functions and Property <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'redux-element-pre-defined-functions')}/></h2>
                    <p>In the <code>ReduxElement</code> component there are two pre-defined functions and one property.</p>
                    <ul>
                        <li><code>mapStateToProps : function</code></li>
                        <li><code>mapDispatchToProps : function</code></li>
                        <li><code>props : property</code></li>
                    </ul>
                </section>
                <section className="docs-section" id="defining-mapStateToProps">
                    <h2 className="section-heading"><code>{'mapStateToProps: (state) => Object '}</code> <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'defining-mapStateToProps')} /></h2>
                    <p><code>mapStateToProps</code> should be defined as a function:</p>   
                    <CodePanel id="mapStateToPropsJS" jsFileName="counter.js" js={mapStateToPropsJS}></CodePanel>
                    <p className="margin-top-small">It should take a first argument called state, and return a plain object containing the data that the connected component needs. This function will be called every time when the Redux store state changes. </p>

                    <h3 className="sub-section-heading">Arguments </h3>
                    <p>This method has only one arguments called <code><b>state</b></code>. This variable has the entire Redux store state. </p>
                    <p>The <code>mapStateToProps</code> function should always be written with <code>state</code> passed in.</p>
                
                    <h3 className="sub-section-heading">Return </h3>
                    <p>Your mapStateToProps function should return a plain object that contains the data the component needs:</p>
                    <ul>
                        <li>Each field in the object will become a prop for your actual component.</li>
                        <li>The values in the fields will be used to determine if your component needs to re-render.</li>
                    </ul>
                </section>
                
                <section className="docs-section" id="defining-mapDispatchToProps">
                    <h2 className="section-heading"><code>{'mapDispatchToProps: () => Object'}</code><FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'defining-mapDispatchToProps')} /></h2>
                    <p>Defining <code>mapDispatchToProps</code> as a function gives you the most flexibility in customizing the functions your component receives, and how they dispatch actions.</p>  
                    <p>This function don't have any arguments, below is the implementation for the function.</p> 
                    <CodePanel id="mapDispatchToPropsWithActionJS" jsFileName="counter.js" js={mapDispatchToPropsWithActionJS}></CodePanel>
                    <p className="margin-top-small"> <code>ReduxElement</code> will dispatch these action creators and map those to <code>props</code>, so that you can use this in the html file like <code>props.increment</code> and the js file you can use like <code>this.props.increment()</code>. Below are the code snippet: </p>
                    <CodePanel id="mapDispatchToPropsWithActionHTML" htmlFileName="counter.html" html={mapDispatchToPropsWithActionHTML} htmlFirst></CodePanel>
                
                    <h3 className="margin-top-small sub-section-heading">Return </h3>
                    <p>Your mapDispatchToProps function should return a plain object:</p>
                    <ul>
                        <li>Each field in the object will become a separate prop for your own component, and the value should normally be a function that dispatches an action when called.</li>
                        <li>Your action creator can be directly use in the <code>mapDispatchToProps</code></li>
                    </ul>
                </section>

                <section className="docs-section" id="accessing-the-store">
                    <h2 className="section-heading">Accessing the Store through <code>props</code> <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'accessing-the-store')} /></h2>
                    <p>To access the store we always need to use props property of the ReduxElement component. In your component, <code>mapStateToProps</code> function will provide the state in the props variable. In the below code, you will find <code>mapStateToProps</code> function and use of the props.</p>
                    <CodePanel id="counterJS" jsFileName="counter.js" js={reduxElementJS}></CodePanel>
                    <p className="margin-top-small">In the above code, we implemented the <code>mapStateToProps</code> and <code>mapDispatchToProps</code> function. Below code will show how to access this in HTML file using props.  </p>
                    <CodePanel id="counterHTML" htmlFileName="counter.html" html={reduxElementHTML} htmlFirst></CodePanel>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton hideRight leftLabel="Provider" leftURL="/provider"  ></NavigationButton>
                </section>
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default ReduxElement;