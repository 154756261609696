import React, {Component} from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import Navbar from '../navbar/navbar';
import {BrowserRouter as Router} from "react-router-dom";
import SwitchComponent from '../switchComponent/switchComponent';
import {scrollTo} from '../../utils/helper';
class Body extends Component{
    componentDidMount() {
        window.addEventListener('load', this.handelLoad);
    }
    
    componentWillUnmount() { 
       window.removeEventListener('load', this.handelLoad)  
    }
    handelLoad(){
        if(window.location.hash) {
            scrollTo(window.location.hash.substr(1));
        }
    }
    render(){
        return(
          <div className="body">
            <Router>
                <div className="docs-page"> 
                    <Header></Header>
                    <div className="docs-wrapper">
                        <Navbar></Navbar>
                        <div>
                        <SwitchComponent></SwitchComponent>
                        </div>  
                    </div>
                    <Footer></Footer>
                    
                </div>
            </Router>
            
          </div>
        );
    }
}

export default Body;