import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {providerJS,providerHTML,reduxElementJS,reduxElementHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import Accordion from '../../components/accordion/accordion';
import {scrollTo} from '../../utils/helper';
import {Link} from "react-router-dom";
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';

class QuickStart extends Component{
    state = {rightNav : [
        {
            name : 'Installation',
            id : 'installation'
        },
        {
            name : 'Provider',
            id : 'provider'
        },
        {
            name : 'ReduxElement',
            id : 'redux-element'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Quick Start </h1>
                    <section className="docs-intro">
                        <p>
                            <a href="#">LWC-redux</a> is a <a href="https://developer.salesforce.com/blogs/2018/12/introducing-lightning-web-components.html" target="_blank">LWC</a> binding for <a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux</a>. It will allow your Lightning Web Components to read data from a Redux store, and dispatch actions to the store to update data.
                            <br></br><br></br>
                            LWC-redux helps you to write applications that behaves consistent and provide state management to the application. It's also separate the JS business and design layers.
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="installation">
                    <h2 className="section-heading">Installation <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'installation')} /></h2>

                    <div className="accordion">
                        <Accordion title="Version 3 (Latest)"  active>
                            <p>This version enhance the performance of the library and it's also support previous versions. To install this version, we only need to click on the below button. Whenever you select the below buttons it will redirect you to another page, where you need to login with org credential. </p>
                            <a className="btn btn-link" href="https://login.salesforce.com/packaging/installPackage.apexp?p0=04t2v000007GTFkAAO" target="_blank"><FontAwesomeIcon icon={faSalesforce}/><span className="ml-2">Deploy to Developer</span></a>
                            <a className="ml-5 btn btn-link btn-sandbox" href="https://test.salesforce.com/packaging/installPackage.apexp?p0=04t2v000007GTFkAAO" target="_blank"><FontAwesomeIcon icon={faSalesforce}/><span className="ml-2">Deploy to Sandbox</span></a>
                            <p className="margin-top-small">Or, run below command to install the package</p>
                            <code>sfdx force:package:install --package 04t2v000007GTFkAAO</code>
                        </Accordion>
                        <Accordion title="Version 2">
                            <p>This version enhance the Logging functionality. To install this version, we only need to click on the below button. Whenever you select the below buttons it will redirect you to another page, where you need to login with org credential. </p>
                            <a className="btn btn-link" href="https://login.salesforce.com/packaging/installPackage.apexp?p0=04t2v0000079B7xAAE" target="_blank"><FontAwesomeIcon icon={faSalesforce}/><span className="ml-2">Deploy to Developer</span></a>
                            <a className="ml-5 btn btn-link btn-sandbox" href="https://test.salesforce.com/packaging/installPackage.apexp?p0=04t2v0000079B7xAAE" target="_blank"><FontAwesomeIcon icon={faSalesforce}/><span className="ml-2">Deploy to Sandbox</span></a>
                            <p className="margin-top-small">Or, run below command to install the package</p>
                            <code>sfdx force:package:install --package 04t2v0000079B7xAAE</code>
                        </Accordion>
                        <Accordion title="Version 1">
                            <p>This version has the base component and connect the LWC component to the Redux store. To install the LWC-redux Version 1, we only need to click on the below button. Whenever you select the below buttons it will redirect you to another page, where you need to login with org credential. </p>
                            <a className="btn btn-link" href="https://login.salesforce.com/packaging/installPackage.apexp?p0=04t2v00000799S7AAI" target="_blank"><FontAwesomeIcon icon={faSalesforce}/><span className="ml-2">Deploy to Developer</span></a>
                            <a className="ml-5 btn btn-link btn-sandbox" href="https://test.salesforce.com/packaging/installPackage.apexp?p0=04t2v00000799S7AAI" target="_blank"><FontAwesomeIcon icon={faSalesforce}/><span className="ml-2">Deploy to Sandbox</span></a>
                            <p className="margin-top-small">Or, run below command to install the package</p>
                            <code>sfdx force:package:install --package 04t2v00000799S7AAI</code>
                        </Accordion>
                    </div>
                </section>
                
                <section className="docs-section" id="provider">
                    <h2 className="section-heading">Provider <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'provider')}/></h2>
                    <p>Provider is a lightning web component, which makes the Redux store available for your application. Your root component should be the child of the <code>{'<c-provider/>'}</code>.</p>
                    <CodePanel id="counterContainer" htmlFileName="counterContainer.html" jsFileName="counterContainer.js" js={providerJS} html={providerHTML} htmlFirst></CodePanel>
                </section>
                
                <section className="docs-section" id="redux-element">
                    <h2 className="section-heading">ReduxElement <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'redux-element')}/></h2>
                    <p>ReduxElement is connector element that connect Lightning Web Component to Redux store. It's is an <code>es6</code> class that extends all the feature of the LightingElement provided by Salesforce. We can also use the <code>Redux</code> method on the base component to to make it redux element as you can see in the below example.</p>
                    <CodePanel id="counter" htmlFileName="counter.html" jsFileName="counter.js" js={reduxElementJS} html={reduxElementHTML}></CodePanel>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton hideLeft rightLabel="Why Use LWC Redux?" rightURL="/why-use-lwc-redux"></NavigationButton>
                </section>
                
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default QuickStart;