export const providerJS=`    
import { LightningElement, api } from 'lwc';
import {createStore, combineReducers, createLogger} from 'c/lwcRedux';
import reducers from 'c/counterReducers';

// Set ENABLE_LOGGING true if you wanna use the logger.
// We prefer to use the Custom label because we can directly access in the LWC components.
const ENABLE_LOGGING = true;

export default class CounterContainer extends LightningElement {
    @api store;
    initialize(){
        let logger;
        
        // Check for the Logging
        if(ENABLE_LOGGING){
            logger = createLogger({
                duration: true,
                diff: true
            });
        }
        
        const combineReducersInstance = combineReducers(reducers);
        this.store = createStore(combineReducersInstance, logger);
    }
}`

export const providerHTML = `
<template>
    <c-provider oninit={initialize} store={store}>
        <c-counter></c-counter>
    </c-provider>
</template>`;

export const reduxElementJS = `
import { LightningElement } from 'lwc';
import { Redux } from 'c/lwcRedux';
import {increment, decrement, reset} from 'c/counterActions';

export default class Counter extends Redux(LightningElement) {
    mapStateToProps(state){
        return {counter: state.counter};
    }
    mapDispatchToProps(){
        return {increment, decrement, reset};
    }
}`;

export const reduxElementHTML =`
<template>
    <lightning-card  title="Counter App">
        <div class="container">
            {props.counter}
        </div>
        <div slot="footer">
            <lightning-button onclick={props.increment} label="Increase" title="Non-primary action"  class="slds-m-left_x-small"></lightning-button>
            <lightning-button onclick={props.reset} label="Reset" title="Non-primary action"  class="slds-m-left_x-small"></lightning-button>
            <lightning-button onclick={props.decrement} label="Decrease" title="Non-primary action"  class="slds-m-left_x-small"></lightning-button>
        </div>
    </lightning-card>
</template>`

export const todoContainerElementHTML =`
<template>
    <c-provider oninit={initialize} store={store}>
        <c-todo-app></c-todo-app>
    </c-provider>
</template>`

export const todoContainerJS =`
import { LightningElement, api } from 'lwc';
import {createStore, combineReducers, createLogger} from 'c/lwcRedux';
import reducers from 'c/todoAppReducer';

// Set ENABLE_LOGGING true if you wanna use the logger.
// We prefer to use the Custom label because we can directly access in the LWC components.
let ENABLE_LOGGING = true;

export default class TodoAppContainer extends LightningElement {
    @api store;
    initialize(){
        let logger;
        
        if(ENABLE_LOGGING){
            logger = createLogger({
                duration: true,
                diff: true
            });
        }
        const combineReducersInstance = combineReducers(reducers);
        this.store = createStore(combineReducersInstance, logger);
    }
}`

export const todoReducerJS =`
import {ADD_TODO, CHANGE_TODO_STATUS, STATUS} from 'c/todoAppConstant';

const initialState = {
    allIds: [],
    byIds: {}
};

const todo = (state = initialState, action) => {
    switch (action.type) {
       case ADD_TODO: 
        const { id, content } = action.payload;
        return {
            ...state,
            allIds: [...state.allIds, id],
            byIds: {
                ...state.byIds,
                [id]: {
                    content,
                    status: STATUS.INCOMPLETE
                }
            }
        };
       case CHANGE_TODO_STATUS: {
        const { id, status } = action.payload;
        return {
            ...state,
            byIds: {
              ...state.byIds,
              [id]: {
                ...state.byIds[id],
                status: status
              }
            }
          };
       }
       default: return state;
    }
}

export default todo;`

export const filterReducerJS =`
import {SET_FILTER, VISIBILITY_FILTER} from 'c/todoAppConstant';

const initialState = VISIBILITY_FILTER.ALL;

const filter = (state = initialState, action) => {
    switch (action.type) {
       case SET_FILTER: return action.payload.filter;
       default: return state;
    }
}

export default filter;`

export const todoAppReducerJS =`
import todo from "./todo";
import filter from "./filter";

export default {
    todo,
    filter
}`;

export const todoActionJS = `
import {ADD_TODO, CHANGE_TODO_STATUS} from 'c/todoAppConstant';
let nextTodoId = 0;

export const addTodo = content => ({
  type: ADD_TODO,
  payload: {
    id: ++nextTodoId,
    content
  }
});

export const changeTodoStatus = (id, status )=> ({
  type: CHANGE_TODO_STATUS,
  payload: { id, status }
});`

export const filterActionJS =`
import {SET_FILTER} from 'c/todoAppConstant';

export const setFilter = filter => ({ 
    type: SET_FILTER, 
    payload: { filter } 
});`;

export const todoAppActionJS =`
import * as todo from "./todo"
import * as filter from "./filter"

export default {
    todo,
    filter
}`;

export const addTodoHTML = `
<template>
    <div class="slds-grid slds-gutters">
        <div class="slds-col slds-size_10-of-12">
            <input value={todoInput} type="text" placeholder="Type task.." onchange={inputChange} class="slds-input" />
        </div>
        <div class="slds-col slds-size_2-of-12">
            <lightning-button variant="brand" label="Submit" title="Submit" onclick={handleClick} class="slds-m-left_x-small"></lightning-button>
        </div>
    </div>
</template>`;

export const addTodoJS = `
import { LightningElement, track } from 'lwc';
import { Redux } from 'c/lwcRedux';
import {todo} from 'c/todoAppActions';

export default class AddTodo extends Redux(LightningElement) {
    @track todoInput = '';
    mapDispatchToProps(){
        return {addTodo : todo.addTodo};
    }
    inputChange(event){
        this.todoInput = event.target.value;
    }
    handleClick(event){
        if(this.todoInput){
            this.props.addTodo(this.todoInput);
            this.todoInput = '';
        }
    }
}`;


export const todoListHTML = `
<template>
    <template if:true={hasRecord}>
        <template for:each={props.allIds} for:item="id">
            <c-todo key={id} record-id={id}></c-todo>
        </template>
    </template>
    <template if:false={hasRecord}>
        <div class="slds-m-top_medium"><b>No item found</b></div>
    </template>
</template>`;

export const todoListJS = `
import { LightningElement } from 'lwc';
import { Redux } from 'c/lwcRedux';

export default class TodoList extends Redux(LightningElement) {
    mapStateToProps(state){
        const { filter, todo } = state;
        let allIds;
        if(filter != 'All'){
            allIds = todo.allIds.filter(id => todo.byIds[id].status == filter)
        }else{
            allIds = todo.allIds
        }      
        return {allIds}
    }
    get hasRecord(){
        return this.props.allIds && this.props.allIds.length > 0
    }
}`;

export const todoHTML = `
<template>
    <div class={bodyClass}>
        <article class="slds-card">
            <div class="slds-card__header slds-grid">
              <header class="slds-media slds-media_center slds-has-flexi-truncate">
                <div class="slds-media__figure">
                  <span class="slds-icon_container slds-icon-standard-account" title="account">
                    <lightning-icon size="medium" icon-name="standard:event" alternative-text="Task" title="Event"></lightning-icon>
                    <span class="slds-assistive-text">{props.record.content}</span>
                    
                  </span>
                </div>
                <div class="slds-media__body">
                  <h2 class="slds-card__header-title">
                    <a href="javascript:void(0);" class="slds-card__header-link slds-truncate" title="Accounts">
                      <span>{props.record.content}</span>
                    </a>
                  </h2>
                  <p class="">{props.record.status}</p>
                </div>
                <div class="slds-no-flex">
                    <template if:false={isCompleted}>
                        <lightning-button-menu alternative-text="Show menu">
                            <lightning-menu-item value={status.INCOMPLETE} label="In complete" onclick={handleStatusChange}></lightning-menu-item>
                            <lightning-menu-item value={status.INPROGRESS} label="In Progress" onclick={handleStatusChange}></lightning-menu-item>
                            <lightning-menu-item value={status.COMPLETED} label="Completed" onclick={handleStatusChange}></lightning-menu-item>
                        </lightning-button-menu>
                    </template>
                </div>
              </header>
            </div>
        </article>   
    </div>
</template>`;

export const todoJS = `
import { LightningElement, api } from 'lwc';
import { Redux } from 'c/lwcRedux';
import {STATUS} from 'c/todoAppConstant';
import {todo} from 'c/todoAppActions';

export default class Todo extends Redux(LightningElement) {
    @api recordId;
    status = STATUS;
    mapStateToProps(state){
        return {record : state.todo.byIds[this.recordId]}
    }
    mapDispatchToProps(){
        return {changeTodoStatus : todo.changeTodoStatus};
    }
    handleStatusChange(event){
        this.props.changeTodoStatus(this.recordId, event.target.value);
    }

    get bodyClass(){
        let strClass = 'slds-m-top_medium slds-box slds-box_xx-small ';
        if(this.props.record.status == STATUS.INCOMPLETE){
            strClass += 'incomplete';
        }else if(this.props.record.status == STATUS.INPROGRESS){
            strClass += 'inprogress';
        }else if(this.props.record.status == STATUS.COMPLETED){
            strClass += 'completed';
        }
        return strClass;
    }
    get isCompleted(){
        return this.props.record.status == STATUS.COMPLETED
    }
}`;


export const todoFilterHTML = `
<template>
    <div class="slds-button-group slds-m-top_medium" role="group">
        <button class="slds-button slds-button_neutral" onclick={handleClick} value={visibilityFilter.ALL}>{visibilityFilter.ALL}</button>
        <button class="slds-button slds-button_neutral" onclick={handleClick} value={visibilityFilter.INCOMPLETE}>{visibilityFilter.INCOMPLETE}</button>
        <button class="slds-button slds-button_neutral" onclick={handleClick} value={visibilityFilter.INPROGRESS}>{visibilityFilter.INPROGRESS}</button>
        <button class="slds-button slds-button_neutral" onclick={handleClick} value={visibilityFilter.COMPLETED}>{visibilityFilter.COMPLETED}</button>
    </div>
</template>`;

export const todoFilterJS = `
import { LightningElement } from 'lwc';
import { Redux } from 'c/lwcRedux';
import {VISIBILITY_FILTER} from 'c/todoAppConstant';
import {filter} from 'c/todoAppActions';
export default class TodoFilter extends Redux(LightningElement) {
    visibilityFilter = VISIBILITY_FILTER
    mapDispatchToProps(){
        return {setFilter : filter.setFilter}
    }
    handleClick(event){
        this.props.setFilter(event.target.value);
    }
}`;

export const mapStateToPropsJS = `
import { LightningElement } from 'lwc';
import { Redux } from 'c/lwcRedux';

export default class Counter extends Redux(LightningElement) {
    mapStateToProps(state){
        return {counter: state.counter};
    }
}`;

export const mapDispatchToPropsJS = `
import { LightningElement } from 'lwc';
import { Redux } from 'c/lwcRedux';
import {increment, decrement, reset} from 'c/counterActions';

export default class Counter extends Redux(LightningElement) {
    mapDispatchToProps(){
        return {increment, decrement, reset};
    }
}`;

export const mapDispatchToPropsWithActionJS = `
const increment = () => ({ type: 'INCREMENT' })
const decrement = () => ({ type: 'DECREMENT' })
const reset = () => ({ type: 'RESET' })

export default class Counter extends Redux(LightningElement) {
    mapDispatchToProps(){
        return {
            // dispatching actions returned by action creators
            increment,
            decrement,
            reset
        }
    }
}`;

export const mapDispatchToPropsWithActionHTML = `
<template>
    <lightning-button onclick={props.increment} label="Increase"></lightning-button>
    <lightning-button onclick={props.reset} label="Reset"></lightning-button>
    <lightning-button onclick={props.decrement} label="Decrease"></lightning-button>
</template>
`;

export const serverCallJS = `
export const initialize = () => {
    return (dispatch, getState) => {
        //Server call to get contact list
        getContactList()
            .then(result => {
                dispatch({
                    type:INITIALIZE_CONTACT_LIST,
                    payload: result
                })
            })
            .catch(error => {
                dispatch({
                    type:ERROR,
                    payload: error
                })
            });
    }
}
`

export const defaultLoggerJs = `
const initialLogger = {
    level: 'log',
    logger: console,
    logErrors: true,
    collapsed: undefined,
    predicate: undefined,
    duration: false, // By default, duration is false
    timestamp: true,
    stateTransformer: state => JSON.parse(JSON.stringify(state)),
    actionTransformer: action => JSON.parse(JSON.stringify(action)),
    errorTransformer: error => JSON.parse(JSON.stringify(error)),
    colors: {
      title: () => 'inherit',
      prevState: () => '#9E9E9E',
      action: () => '#03A9F4',
      nextState: () => '#4CAF50',
      error: () => '#F20404',
    },
    diff: false, // By default, diff is false
    diffPredicate: undefined,
};
`

