import React, {Component} from "react";
import {
    Switch,
    Route,
} from "react-router-dom";
import QuickStart from '../pages/quickStart';
import BasicTutorial from '../pages/basicTutorial';
import WhyUse from '../pages/whyUse';
import ExtractingDataWithMapStateToProps from '../pages/extractingDataWithMapStateToProps';
import DispatchingActionsWithMapDispatchToProps from '../pages/dispatchingActionsWithMapDispatchToProps';
import UsingProps from '../pages/usingProps';
import ServerInteraction from '../pages/serverInteraction';
import Provider from '../pages/provider';
import ReduxElement from '../pages/reduxElement';
import Understandings from '../pages/understandings';
import Examples from '../pages/examples';
import PrivacyPolicy from '../pages/privacyPolicy';
import TermsAndCondition from '../pages/termsAndCondition';
import Logger from '../pages/logger';


class SwitchComponent extends Component{
    render(){
        return(
            <Switch>
                <Route path="/quick-start">
                    <QuickStart></QuickStart>
                </Route>
                <Route path="/basic-tutorial">
                    <BasicTutorial></BasicTutorial>
                </Route>
                <Route path="/why-use-lwc-redux">
                    <WhyUse></WhyUse>
                </Route>
                <Route path="/extracting-data-with-mapStateToProps">
                    <ExtractingDataWithMapStateToProps></ExtractingDataWithMapStateToProps>
                </Route>
                <Route path="/dispatching-actions-with-mapDispatchToProps">
                    <DispatchingActionsWithMapDispatchToProps></DispatchingActionsWithMapDispatchToProps>
                </Route>
                <Route path="/using-props">
                    <UsingProps></UsingProps>
                </Route>
                <Route path="/server-interacting">
                    <ServerInteraction></ServerInteraction>
                </Route>
                <Route path="/logger">
                    <Logger></Logger>
                </Route>
                <Route path="/provider">
                    <Provider></Provider>
                </Route>
                <Route path="/redux-element">
                    <ReduxElement></ReduxElement>
                </Route>
                <Route path="/understandings">
                    <Understandings></Understandings>
                </Route>
                <Route path="/examples">
                    <Examples></Examples>
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicy></PrivacyPolicy>
                </Route>
                <Route path="/terms-and-condition">
                    <TermsAndCondition></TermsAndCondition>
                </Route>
                <Route path="/">
                    <QuickStart></QuickStart>
                </Route>
            </Switch>
        );
    }

}

export default SwitchComponent;