import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './navigationButton.css';
class NavigationButton extends Component{
    constructor(props){
        super(props)
    }
    render(){
        let leftButton = "";
        let rightButton = "";
        if(!this.props.hideLeft){
        leftButton = (<a className="btn btn-link text-truncate" href={this.props.leftURL}><FontAwesomeIcon icon={faAngleLeft}/><span className="ml-2">{this.props.leftLabel}</span></a>);
        }
        if(!this.props.hideRight){
            rightButton = (<a className="btn btn-link right text-truncate" href={this.props.rightURL} ><span className="mr-2">{this.props.rightLabel}</span><FontAwesomeIcon icon={faAngleRight}/></a>);
        }
        return(
            <div className="row">
                <div className="col-6">{leftButton}</div>
                <div className="col-6">{rightButton}</div>
            </div>
        );
    }
}

export default NavigationButton;