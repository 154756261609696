import React, {Component} from 'react';
import NavItemHeader from '../../components/navItemHeader/navItemHeader';
import NavItem from '../../components/navItem/navItem';
import {scrollToTop} from '../../utils/helper'
import { withRouter } from 'react-router-dom'

import { faCoffee, faGlassCheers, faCapsules, faBabyCarriage } from '@fortawesome/free-solid-svg-icons';
class Navbar extends Component{
    state = {activePage: window.location.pathname != '/'? window.location.pathname :'/quick-start'}
    
    handleClick(pageName){
        this.setState({activePage: pageName})
        const sidebarEle = document.querySelector('#docs-sidebar');
        if(sidebarEle){
            if(sidebarEle.classList.contains('display-side-bar')){
                sidebarEle.classList.remove("display-side-bar");
            }
        }
        scrollToTop();
    }
    render(){
        return(
            <div id="docs-sidebar" className="docs-sidebar left-bar">
                <nav id="docs-nav" className="docs-nav navbar">
                    <ul className="section-items list-unstyled nav flex-column pb-3">
                        <NavItemHeader icon={faCoffee}  title="Introduction"></NavItemHeader>
                        <NavItem name="Quick Start" href="/quick-start" isActive={this.state.activePage == '/quick-start'? 'true':'false'} click={()=>this.handleClick('/quick-start')}></NavItem>
                        <NavItem name="Why use LWC Redux" href="/why-use-lwc-redux" isActive={this.state.activePage == '/why-use-lwc-redux'? 'true':'false'} click={()=>this.handleClick('/why-use-lwc-redux')}></NavItem>
                        <NavItem name="Basic Tutorial" href="/basic-tutorial" isActive={this.state.activePage == '/basic-tutorial'? 'true':'false'} click={()=>this.handleClick('/basic-tutorial')}></NavItem>
                        
                        
                        <NavItemHeader icon={faGlassCheers}  title="Using LWC Redux"></NavItemHeader>
                        <NavItem name="Extracting Data with mapStateToProps" href="/extracting-data-with-mapStateToProps" isActive={this.state.activePage == '/extracting-data-with-mapStateToProps'? 'true':'false'} click={()=>this.handleClick('/extracting-data-with-mapStateToProps')}></NavItem>
                        <NavItem name="Dispatching Actions with mapDispatchToProps" href="/dispatching-actions-with-mapDispatchToProps" isActive={this.state.activePage == '/dispatching-actions-with-mapDispatchToProps'? 'true':'false'} click={()=>this.handleClick('/dispatching-actions-with-mapDispatchToProps')}></NavItem>
                        <NavItem name="Using Props" href="/using-props" isActive={this.state.activePage == '/using-props'? 'true':'false'} click={()=>this.handleClick('/using-props')}></NavItem>
                        <NavItem name="Server Interaction" href="/server-interacting" isActive={this.state.activePage == '/server-interacting'? 'true':'false'} click={()=>this.handleClick('/server-interacting')}></NavItem>
                        <NavItem name="Logger" href="/logger" isActive={this.state.activePage == '/logger'? 'true':'false'} click={()=>this.handleClick('/logger')}></NavItem>
                        
                        <NavItemHeader icon={faCapsules}  title="API Reference"></NavItemHeader>
                        <NavItem name="Provider" href="/provider" isActive={this.state.activePage == '/provider'? 'true':'false'} click={()=>this.handleClick('/provider')}></NavItem>
                        <NavItem name="ReduxElement" href="/redux-element" isActive={this.state.activePage == '/redux-element'? 'true':'false'} click={()=>this.handleClick('/redux-element')}></NavItem>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Navbar;