import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {providerJS,providerHTML,reduxElementJS,reduxElementHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class Understandings extends Component{
    state = {rightNav : [
        {
            name : 'Installation',
            id : 'installation'
        },
        {
            name : 'Provider',
            id : 'provider'
        },
        {
            name : 'ReduxElement',
            id : 'redux-element'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Understandings </h1>
                    <section className="docs-intro">
                        <p>
                            <a href="#">LWC-redux</a> is a official <a href="https://developer.salesforce.com/blogs/2018/12/introducing-lightning-web-components.html" target="_blank">LWC</a> binding for <a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux</a>. It will allow your Lightning Web Components to read data form a Redux store, and dispatch actions to the store to update data.
                            <br></br><br></br>
                            LWC-redux helps you to write application that behaves consistently and provide state management to the application. It's also separate the JS business and design layers.
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="installation">
                    <h2 className="section-heading">Installation <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'installation')} /></h2>
                    <p>To install the LWC-redux, we only need to click on the below button. It will redirect you to another page where you can select production/sandbox org and proceed with 'Login to Salesforce' button. </p>
                    <a href="https://githubsfdeploy.herokuapp.com?owner=chandrakiran-div&repo=lwc-redux" target="_blank">
                    <img alt="Deploy to Salesforce"
                        src="https://raw.githubusercontent.com/afawcett/githubsfdeploy/master/deploy.png"/>
                    </a>
                    
                </section>
                
                <section className="docs-section" id="provider">
                    <h2 className="section-heading">Provider <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'provider')}/></h2>
                    <p>Provider is a lightning web component, which makes the Redux store available for you application. Your root component should be the child of the <code>{'<c-provider/>'}</code>.</p>
                    <CodePanel id="counterContainer" htmlFileName="counterContainer.html" jsFileName="counterContainer.js" js={providerJS} html={providerHTML} htmlFirst></CodePanel>
                </section>
                
                <section className="docs-section" id="redux-element">
                    <h2 className="section-heading">ReduxElement <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'redux-element')}/></h2>
                    <p>ReduxElement is connector element that connect Lightning Web Component to Redux store. It's is an <code>es6</code> class that extends all the feature of the LightingElement provided by Salesforce.</p>
                    <CodePanel id="counter" htmlFileName="counter.html" jsFileName="counter.js" js={reduxElementJS} html={reduxElementHTML}></CodePanel>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default Understandings;