import React, {Component} from 'react';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class WhyUse extends Component{
    state = {rightNav : [
        {
            name : 'Integrating Redux with a UI',
            id : 'integrating-redux-with-a-ui'
        },
        {
            name : 'Reasons to Use LWC Redux',
            id : 'reasons-to-use-lwc-redux'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Why Use LWC Redux? </h1>
                    <section className="docs-intro">
                        <p>Redux itself is a standalone library that can be used with any UI layer or framework, including LWC, React, Angular, Vue, Ember, and vanilla JS. Although Redux and React are commonly used together, they are independent of each other.</p>
                        <p>If you are using Redux with any kind of UI framework, you will normally use a "UI binding" library to tie Redux together with your UI framework, rather than directly interacting with the store from your UI code.</p>
                        <p>LWC Redux is the Redux UI binding library for LWC. If you are using Redux and LWC together, you should also use LWC Redux to bind these two libraries.</p>
                    </section>
                </header>
                <section className="docs-section" id="integrating-redux-with-a-ui">
                    <h2 className="section-heading">Integrating Redux with a UI <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'integrating-redux-with-a-ui')} /></h2>
                    <p>Using Redux with any UI layer requires <a href="https://blog.isquaredsoftware.com/presentations/workshops/redux-fundamentals/ui-layer.html#/4">the same consistent set of steps:</a> </p>
                    <ul>
                        <li>Create a Redux store</li>
                        <li>Subscribe to updates</li>
                        <li>Inside the subscription callback:
                            <ul>
                                <li>Get the current store state</li>
                                <li>Extract the data needed by this piece of UI</li>
                                <li>Update the UI with the data</li>
                            </ul>
                        </li>
                        <li>If necessary, render the UI with initial state</li>
                        <li>Respond to UI inputs by dispatching Redux actions</li>
                    </ul>
                    <p>While it is possible to write this logic by hand, doing so would become very repetitive. In addition, optimizing UI performance would require complicated logic.</p>
                    <p>The process of subscribing to the store, checking for updated data, and triggering a re-render can be made more generic and reusable. <b>A UI binding library like LWC Redux handles the store interaction logic, so you don't have to write that code yourself</b>.</p>
                </section>
                
                <section className="docs-section" id="reasons-to-use-lwc-redux">
                    <h2 className="section-heading">Reasons to Use LWC Redux <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'reasons-to-use-lwc-redux')}/></h2>
                    <p><b>It is the Redux UI Bindings for LWC</b>.</p>
                    <p>As the Redux binding for LWC, LWC Redux is kept up-to-date with any API changes from either library, to ensure that your LWC components behave as expected. Its intended usage adopts the design principles of LWC - writing declarative components.</p>
                    <p><b>It Encourages Good LWC Architecture</b></p>
                    <p>LWC components are a lot like functions. While it's possible to write all your code in a single function, it's usually better to split that logic into smaller functions that each handle a specific task, making them easier to understand.</p>
                    <p>Similarly, while you can write large LWC components that handle many different tasks, it's usually better to split up components based on responsibilities. In particular, it is common to have "container" components that are responsible for collecting and managing some kind of data, and "presentational" components that simply display UI based on whatever data they've received as props.</p>
                    <p>The LWC Redux connect function generates "container" wrapper components that handle the process of interacting with the store for you. That way, your own components can focus on other tasks, whether it be collecting other data, or just displaying a piece of the UI. In addition, connect abstracts away the question of which store is being used, making your own components more reusable.</p>
                    <p>As a general architectural principle, we want to keep our own components "unaware" of Redux. They should simply receive data and functions as props, just like any other LWC component. This ultimately makes it easier to test and reuse your own components.</p>
                    <p><b>It Implements Performance Optimizations For You</b></p>
                    <p>LWC is generally fast, but by default any updates to a component will cause LWC to re-render all of the components inside that part of the component tree. This does require work, and if the data for a given component hasn't changed, then re-rendering is likely some wasted effort because the requested UI output would be the same.</p>
                    <p>If performance is a concern, the best way to improve performance is to skip unnecessary re-renders, so that components only re-render when their data has actually changed. LWC Redux implements many performance optimizations internally, so that your own component only re-renders when it actually needs to.</p>
                    <p>In addition, by connecting multiple components in your LWC component tree, you can ensure that each connected component only extracts the specific pieces of data from the store state that are needed by that component. This means that your own component will need to re-render less often, because most of the time those specific pieces of data haven't changed.</p>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton leftLabel="Quick Start" leftURL="/quick-start" rightLabel="Basic Tutorial" rightURL="/basic-tutorial"></NavigationButton>
                </section>
                
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='whyUse' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default WhyUse;