import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

class Footer extends Component{
    handleClick(page){
        window.location.href = page;
    }
    render(){
        return(
            <footer className="footer-container">
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <ul className="section-items list-unstyled nav flex-column pb-3">
                                <li className="nav-item"><a className="nav-link scrollto" onClick={() => this.handleClick('/quick-start')}>Quick Start</a></li>
                                <li className="nav-item"><a className="nav-link scrollto" onClick={() => this.handleClick('/quick-start')}>Installation</a></li>
                                <li className="nav-item"><a className="nav-link scrollto" onClick={() => this.handleClick('/provider')}>API Reference</a></li>
                                <li className="nav-item"><a className="nav-link scrollto" onClick={() => this.handleClick('/examples')}>Examples</a></li>
                            </ul>
                        </div>
                        <div className="col-4">
                            <ul className="section-items list-unstyled nav flex-column pb-3">
                                <li className="nav-item"><a className="nav-link scrollto" onClick={() => this.handleClick('/privacy-policy')}>Privacy Policy</a></li>
                                <li className="nav-item"><a className="nav-link scrollto" href="https://github.com/chandrakiran-dev/lwc-redux/issues" target="_blank">Register an Issue</a></li>
                            </ul>
                        </div>
                        <div className="col-4">
                            <ul className="section-items list-unstyled nav flex-column pb-3">
                                <li className="nav-item"><a className="nav-link scrollto" href="/basic-tutorial">Basic Tutorial</a></li>
                                <li><a href="https://github.com/chandrakiran-dev/lwc-redux" target="_blank"><FontAwesomeIcon icon={faGithub} className="github-icon"/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright-text">
                        <span className="text-muted ">© 2020 Copyright: lwc-redux.com</span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;