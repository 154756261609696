import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {mapDispatchToPropsWithActionJS,mapDispatchToPropsWithActionHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class DispatchingActionsWithMapDispatchToProps extends Component{
    state = {rightNav : [
        {
            name : 'Defining mapDispatchToProps Function',
            id : 'defining-mapDispatchToProps'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Dispatching Actions with <code>mapDispatchToProps</code> </h1>
                    <section className="docs-intro">
                        <p>This is second pre-defined method for the <code>ReduxElement</code>,  <code>mapDispatchToProps</code> is used for dispatching actions to the store.</p>
                        <p>With LWC Redux, your components never access the store directly - <code>ReduxElement</code> does it for you. LWC Redux gives you two ways to let components dispatch actions:</p>
                        <ul>
                            <li>This is a optional method, If your component want to modify the store then you need to implement <code>mapDispatchToProps</code> in the <code>ReduxElement</code>.</li>
                            <li>This method will dispatch the function and associate those function to <code>props</code> to your component.</li>
                        </ul>
                        <p>The mapDispatchToProps functions are normally referred to as mapDispatch for short, but the actual variable name used can be whatever you want.</p>
                    </section>
                </header>
                <section className="docs-section" id="defining-mapDispatchToProps">
                    <h2 className="section-heading">Defining <code>mapDispatchToProps</code> Function <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'defining-mapDispatchToProps')} /></h2>
                    <p>Defining <code>mapDispatchToProps</code> as a function gives you the most flexibility in customizing the functions your component receives, and how they dispatch actions.</p>  
                    <p>This function don't have any arguments, below is the implementation for the function.</p> 
                    <CodePanel id="mapDispatchToPropsWithActionJS" jsFileName="counter.js" js={mapDispatchToPropsWithActionJS}></CodePanel>
                    <p className="margin-top-small"> <code>ReduxElement</code> will dispatch these action creators and map those to <code>props</code>, so that you can use this in the html file like <code>props.increment</code> and the js file you can use like <code>this.props.increment()</code>. Below are the code snippet: </p>
                    <CodePanel id="mapDispatchToPropsWithActionHTML" htmlFileName="counter.html" html={mapDispatchToPropsWithActionHTML} htmlFirst></CodePanel>
                
                    <h3 className="margin-top-small sub-section-heading">Return </h3>
                    <p>Your mapDispatchToProps function should return a plain object:</p>
                    <ul>
                        <li>Each field in the object will become a separate prop for your own component, and the value should normally be a function that dispatches an action when called.</li>
                        <li>Your action creator can be directly use in the <code>mapDispatchToProps</code></li>
                    </ul>
                </section>
                

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton leftLabel="Extracting Data with mapStateToProps" leftURL="/extracting-data-with-mapStateToProps" rightLabel="Using Props" rightURL="/using-props" ></NavigationButton>
                </section>
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default DispatchingActionsWithMapDispatchToProps;