import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {reduxElementJS,reduxElementHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class UsingProps extends Component{
    state = {rightNav : [
        {
            name : 'Accessing the Store',
            id : 'accessing-the-store'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Using <code>props</code> </h1>
                    <section className="docs-intro">
                        <p>
                            LWC-redux provide the predefined property called <code>props</code> in the <code>ReduxElement</code>. All the store data and dispatch function will accessible with this property in the your component. 
                            <br></br><br></br>
                            This will helps you to access the store data and the action function. Inside the component, you don't need to define this property, you can directly use like <code>this.props.increment</code> in the js file.
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="accessing-the-store">
                    <h2 className="section-heading">Accessing the Store <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'accessing-the-store')} /></h2>
                    <p>To access the store we always need to use props property of the ReduxElement component. In your component, <code>mapStateToProps</code> function will provide the state in the props variable. In the below code, you will find <code>mapStateToProps</code> function and use of the props.</p>
                    <CodePanel id="counterJS" jsFileName="counter.js" js={reduxElementJS}></CodePanel>
                    <p className="margin-top-small">In the above code, we implemented the <code>mapStateToProps</code> and <code>mapDispatchToProps</code> function. Below code will show how to access this in HTML file using props.  </p>
                    <CodePanel id="counterHTML" htmlFileName="counter.html" html={reduxElementHTML} htmlFirst></CodePanel>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton leftLabel="Dispatching Action with mapDispatchToProps" leftURL="/dispatching-actions-with-mapDispatchToProps" rightLabel="Server Interaction" rightURL="/server-interacting" ></NavigationButton>
                </section>
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default UsingProps;