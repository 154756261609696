import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

class Header extends Component{
    handleClick(page){
        window.location.href = page;
    }
    toggleSidebar(){
        const sidebarEle = document.querySelector('#docs-sidebar');
        if(sidebarEle){
            if(sidebarEle.classList.contains('display-side-bar')){
                sidebarEle.classList.remove("display-side-bar");
            }else{
                sidebarEle.classList.add("display-side-bar");
            }
        }
    }
    render(){
        return(
          <div className="header">
            <header className="header fixed-top">	    
                <div className="branding docs-branding">
                    <div className="container-fluid position-relative py-2">
                        <div className="docs-logo-wrapper">
                            <div className="site-logo">
                                <FontAwesomeIcon icon={faBars} onClick={() => this.toggleSidebar()}  className="burger-icon"/>
                                <a className="navbar-brand" href="index.html">
                                    <span className="logo-text">lwc-<span className="text-alt">redux</span></span>
                                </a>
                            </div>   
                        </div>
                        <div className="docs-top-utilities d-flex justify-content-end align-items-center">
                            
                            <ul className="social-list list-inline mx-md-3 mx-lg-5 mb-0 d-none d-lg-flex">
                                <li className=""><Link to="/quick-start" onClick={() => this.handleClick('/quick-start')}>Quick Start</Link></li>
                                <li className=""><Link to="/examples" onClick={() => this.handleClick('/examples')}>Examples</Link></li>
                                <li className=""><Link to="/quick-start" onClick={() => this.handleClick('/quick-start')}>Installation</Link></li>
                                <li className=""><Link to="/provider" onClick={() => this.handleClick('/provider')}>API Reference</Link></li>
                                <li className=""><a href="https://github.com/chandrakiran-dev/lwc-redux" target="_blank"><FontAwesomeIcon icon={faGithub} className="github-icon"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
          </div>
        );
    }
}

export default Header;