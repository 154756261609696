import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {providerJS,defaultLoggerJs,reduxElementJS,reduxElementHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import Accordion from '../../components/accordion/accordion';
import {scrollTo} from '../../utils/helper';
import {Link} from "react-router-dom";
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import LoggerImage from '../../asset/images/logger.png';

class Logger extends Component{
    state = {rightNav : [
        {
            name : 'How to Use Logger',
            id : 'how-to-use-logger'
        },
        {
            name : 'Default Logger Configuration',
            id : 'default-logger-configuration'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Logger </h1>
                    <section className="docs-intro">
                        <p>
                            Logging is the key feature for any library. So we have added the middleware for the logging the application stage, this will display all the information like, current action, previous state, next state and the state difference.
                            <br></br><br></br>
                            This will help the analyzing the state wile debugging. Also, this will define the better understating of the redux side, whats going inside th redux.
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="how-to-use-logger">
                    <h2 className="section-heading">How to Use Logger <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'how-to-use-logger')} /></h2>
                    <p>To implement the logger, we need to add a new middleware and pass te logger while calling the <code>createStore</code>. If pass <code>undefined</code>, than It will not use the logger and work without the logging. Below are the for the logging.</p>
                    <CodePanel id="todoAppJSContainer" jsFileName="counterContainer.js" js={providerJS} ></CodePanel>
                    <p className="margin-top-small">Set <code>ENABLE_LOGGING</code> to <code>true</code> if you wanna use the logger. We prefer to use the Custom label to set this because we can directly access this Custom label in the LWC components.</p>
                </section>
                
                <section className="docs-section" id="default-logger-configuration">
                    <h2 className="section-heading">Default Logger Configuration<FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'default-logger-configuration')}/></h2>
                    <p>Below are the default logger configuration, you can change it by passing in the <code>createLogger</code> method</p>
                    <CodePanel id="defaultLogger" jsFileName="defaultLogger.json" js={defaultLoggerJs} ></CodePanel>
                    <p className="margin-top-small">You can use the below method to create the logger instance and pass it to the createStore method</p>
                    <code className="margin-top-small">
                    {`createLogger({
                        duration: true,
                        diff: true
                    });`}
                    </code>

                    <p className="margin-top-small">Logger will look like below content in console. </p>
                    <div className="todo-app-img-container">
                        <img src={LoggerImage} alt="Logger" />
                    </div>
                </section>
                
                <section className="docs-section">
                <NavigationButton leftLabel="Server Interaction" leftURL="/server-interacting" rightLabel="Provider" rightURL="/provider" ></NavigationButton>
                </section>
                
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default Logger;