import React, {Component} from 'react';
import NavItem from '../../components/navItem/navItem';
import {scrollTo} from '../../utils/helper';
import './rightSidebar.css';
class RightSidebar extends Component{
    constructor(props){
        super(props)
    }
    handleClick(id){
        scrollTo(id);
    }
    render(){
        let navItems = this.props.items.map((item, index) => {
            return (
                <NavItem key={item.id} name={item.name} href={'#' + item.id} click={() => this.handleClick(item.id)} ></NavItem>
            )
        })
        return(
            <div id={this.props.id} className="docs-sidebar right">
                <nav id={this.props.id + '-nav'} className="docs-nav navbar">
                    <ul className="section-items list-unstyled nav flex-column pb-3">
                        {navItems}
                    </ul>
                </nav>
            </div>
        );
    }
}

export default RightSidebar;