import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {serverCallJS} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class ServerInteraction extends Component{
    state = {rightNav : [
        {
            name : 'Thunks and Async Logic',
            id : 'thunk-and-async-logic'
        },
        {
            name : 'Writing Async Action',
            id : 'writing-async-action'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Server Interaction </h1>
                    <section className="docs-intro">
                        <p>
                            Using the Redux library, so far all the data we've worked with has been directly inside of our applications in the Sync manner. However, most real applications need to work with data from a server, by making HTTP API calls to fetch and save items.
                            <br></br><br></br>
                            In this section, we'll will know that how our application can interact with the server and perform the operation, like to fetch the TODO list and users data from an API, and add new todo by saving them to the API.
                            <br></br><br></br>
                            <p>Redux mostly focus on to perform the sync operation. Redux store doesn't know anything about async logic. LWC-redux internally use the Redux Tunck to handle the Async operation. Redux Tunck act like a middleware between LWC and Redux.</p>
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="thunk-and-async-logic">
                    <h2 className="section-heading">Thunks and Async Logic <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'thunk-and-async-logic')} /></h2>
                    <p>By itself, a Redux store doesn't know anything about async logic. It only knows how to synchronously dispatch actions, update the state by calling the root reducer function, and notify the UI that something has changed. Any synchronicity has to happen outside the store.</p>
                    <p>But, what if you want to have async logic interact with the store by dispatching or checking the current store state? That's where Redux middleware come in. Below is the syntax for the server call written in the Action file. </p>
                    <CodePanel id="initializeAction" jsFileName="initializeAction.js" js={serverCallJS}></CodePanel>
                </section>

                <section className="docs-section" id="writing-async-action">
                    <h2 className="section-heading">Writing Async Action <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'writing-async-action')}/></h2>
                    <p>All the Async must be call in the Action functions, The most common reason to use middleware is to allow different kinds of async logic to interact with the store. This allows you to write code that can dispatch actions and check the store state, while keeping that logic separate from your UI.</p>
                    <p>Thunks may have async logic inside of them, such as setTimeout, Promises, and async/await. This makes them a good place to put AJAX calls to a server API.</p>
                    <p>Data fetching logic for Redux typically follows a predictable pattern:</p>
                    <ul>
                        <li>A "start" action is dispatched before the request, to indicate that the request is in progress. This may be used to track loading state to allow skipping duplicate requests or show loading indicators in the UI.</li>
                        <li>The async request is made.</li>
                        <li>Depending on the request result, the async logic dispatches either a "success" action containing the result data, or a "failure" action containing error details. The reducer logic clears the loading state in both cases, and either processes the result data from the success case, or stores the error value for potential display.</li>
                    </ul>
                    <p>These steps are not required, but are commonly used. (If all you care about is a successful result, you can just dispatch a single "success" action when the request finishes, and skip the "start" and "failure" actions.)</p>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton leftLabel="Using Props" leftURL="/using-props" rightLabel="Logger" rightURL="/logger" ></NavigationButton>
                </section>
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default ServerInteraction;