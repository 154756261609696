import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import {providerJS,providerHTML,reduxElementJS,reduxElementHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import TodoApp from '../../asset/images/todo-app.png';
import CounterApp from '../../asset/images/counter-app.png';
import StopWatch from '../../asset/images/stop-watch.png';

class Examples extends Component{
    state = {rightNav : [
        {
            name : 'Counter App',
            id : 'counter-app'
        },
        {
            name : 'Stopwatch',
            id : 'stopwatch'
        },
        {
            name : 'Todo App',
            id : 'todo-app'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Examples </h1>
                    <section className="docs-intro">
                        <p>
                        To see how to use LWC Redux in practice, we’ll show some example, all codes are in the <a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/examples/lwc">github repository</a>.
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="counter-app">
                    <h2 className="section-heading">Counter App <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'counter-app')} /></h2>
                    <p>This is very small and simple app to start LWC-redux. In this, we have will some action called increase, decrease and reset. These action will modify the store value and display on the screen. <a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/examples/lwc/counter/lwc" target="_blank">Click here to go to github repository</a></p>
                    <div className="todo-app-img-container">
                        <img src={CounterApp} alt="Counter App" />
                    </div>
                    
                </section>
                
                <section className="docs-section" id="stopwatch">
                    <h2 className="section-heading">Stopwatch <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'stopwatch')}/></h2>
                    <p>In this app, you can start and stop the watcher and create the laps the the same. Here we will see that, how to work with async (like setInterval and setTimeout) and list. <a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/examples/lwc/stopWatch/lwc" target="_blank">Click here to go to github repository</a>  </p>
                    <div className="todo-app-img-container">
                        <img src={StopWatch} alt="Stopwatch" />
                    </div>
                </section>
                
                <section className="docs-section" id="todo-app">
                    <h2 className="section-heading">Todo App <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'todo-app')}/></h2>
                    <p>This is a little advance LWC-redux component where we can learn many concepts. In this app, we can create the tasks and update the status of the task. Also, we can filter the task on the basis of the task status. <a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/examples/lwc/todo/lwc" target="_blank">Click here to go to github repository</a>  </p>
                    <div className="todo-app-img-container">
                        <img src={TodoApp} alt="TodoApp" />
                    </div>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Below are the github link for the examples and the repository.</p>
                    <ul>
                        <li><a href="https://github.com/chandrakiran-dev/lwc-redux" target="_blank">Repository link for LWC-redux</a></li>
                        <li><a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/examples/lwc/counter/lwc" target="_blank">Counter app code link</a></li>
                        <li><a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/examples/lwc/stopWatch/lwc" target="_blank">Stopwatch code link</a></li>
                        <li><a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/examples/lwc/todo/lwc" target="_blank">Todo app code link</a></li>
                    </ul>
                </section>
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default Examples;