import React from 'react';
import {Link} from "react-router-dom";
const NavItem = (props) => {
  let className = 'nav-link scrollto'
  if(props.isActive === 'true'){
    className = className + ' active';
  }
  return (
    
    <li className="nav-item">
        <Link className={className}  to={props.href} onClick={props.click}>{props.name}</Link>
    </li>
  );
};
export default NavItem;