import React from 'react';
import Body from './containers/body/body'
//import logo from './logo.svg';
import './App.css';
import './css/custom.css';
import './css/responsive.css';

function App() {
  return (
      <div className="app">
        <Body></Body>
      </div>
  );
}

export default App;
