import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {todoFilterHTML, todoFilterJS, todoHTML, todoJS, todoListHTML, todoListJS, addTodoHTML, addTodoJS, todoActionJS, filterActionJS, todoAppActionJS, todoReducerJS, filterReducerJS, todoAppReducerJS, todoContainerElementHTML,todoContainerJS} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';
import TodoApp from '../../asset/images/todo-app.png';

class BasicTutorial extends Component{
    state = {rightNav : [
        {
            name : 'A Todo List Example',
            id : 'a-todo-list-example'
        },
        {
            name : 'Providing the Store',
            id : 'providing-the-store'
        },
        {
            name : 'Creating Reducers Functions',
            id : 'creating-reducers-function'
        },
        {
            name : 'Defining Actions',
            id : 'defining-actions'
        },
        {
            name : 'Connecting the Components',
            id : 'connecting-the-components'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}

    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Basic Tutorial</h1>
                    <section className="docs-intro">
                        <p>
                        To see how to use LWC Redux in practice, we’ll show a step-by-step example by creating a todo list app.
                        </p>
                    </section>
                </header>
                <section className="docs-section" id="a-todo-list-example">
                    <h2 className="section-heading">A Todo List Example <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'a-todo-list-example')} /></h2>
                    <div className="todo-app-img-container">
                        <img src={TodoApp} alt="Todo App" />
                    </div>
                    <br></br>
                    <b>The LWC UI Components</b>
                    <div className="margin-top-small">
                        We have implemented our LWC UI components as follows:
                        <ul>
                            <li><code>TodoApp</code> is the entry component for our app. It renders the header, to add the task in the list we renders <code>AddTodo</code>, <code>TodoList</code>, and <code>TodoFilter</code> components.</li>
                            <li><code>AddTodo</code> is the component that allows a user to input a todo item and add to the list upon clicking its “Submit” button:
                                <ul>
                                    <li>It uses a controlled input that sets state upon <code>onChange</code>.</li>
                                    <li>When the user clicks on the “Add Todo” button, it dispatches the action (that we will provide using LWC Redux) to add the todo to the store.</li>
                                </ul>
                            </li>
                            <li><code>TodoList</code> is the component that renders the list of todos:
                                <ul>
                                    <li>It renders the filtered list of todos when one of the <code>TodoFilter</code> is selected.</li>
                                </ul>
                            </li>
                            <li><code>Todo</code> is the component that renders a single todo item:
                                <ul>
                                    <li>It renders the todo content, and shows that a todo is completed by crossing it out.</li>
                                    <li>It dispatches the action to toggle the todo's complete status upon <code>onClick</code>.</li>
                                </ul>
                            </li>
                            <li><code>TodoFilter</code> renders a simple set of filters: <em>all</em>, <em>incomplete</em>, <em>in progress</em>, and <em>completed.</em> Clicking on each one of them filters the todos:
                                <ul>
                                    <li>It dispatches the <code>setFilter</code> action to update the selected filter.</li>
                                </ul>
                            </li>
                            <li><code>TodoAppConstants</code> holds the constants data for our app.</li>
                            <li>And finally <code>TodoAppContainer</code> renders our app to the DOM. It exposed on page layouts.</li>
                        </ul>
                    </div>
                    <br></br>
                    <b>The Redux Store</b>
                    <div className="margin-top-small">
                        We have implemented our LWC redux as follows:
                        <ul>
                            <li>Store
                                <ul>
                                    <li><code>todos</code>: A normalized reducer of todos. It contains a <code>byIds</code> map of all todos and a <code>allIds</code> that contains the list of all ids.</li>
                                    <li><code>todoFilter</code>: A simple string <code>all</code>, <code>completed</code>, or <code>incomplete</code>.</li>
                                </ul>
                            </li>
                            <li>Action Creators
                                <ul>
                                    <li><code>addTodo</code> creates the action to add todos. It takes a single string variable <code>content</code> and returns an <code>ADD_TODO</code> action with <code>payload</code> containing a self-incremented <code>id</code> and <code>content</code></li>
                                    <li><code>changeTodoStatus</code> creates the action to toggle todos. It takes a single number variable <code>id</code> and returns a <code>TOGGLE_TODO</code> action with <code>payload</code> containing <code>id</code> only</li>
                                    <li><code>setFilter</code> creates the action to set the app’s active filter. It takes a single string variable <code>filter</code> and returns a <code>SET_FILTER</code> action with <code>payload</code> containing the <code>filter</code> itself</li>
                                </ul>
                            </li>
                            <li>Reducers
                                <ul>
                                    <li>The <code>todos</code> reducer
                                        <ul>
                                            <li>Appends the <code>id</code> to its <code>allIds</code> field and sets the todo within its <code>byIds</code> field upon receiving the <code>ADD_TODO</code> action</li>
                                            <li>Toggles the <code>completed</code> field for the todo upon receiving the <code>CHANGE_TODO_STATUS</code> action</li>
                                        </ul>
                                    </li>
                                    <li>The <code>todoFilter</code> reducer sets its slice of store to the new filter it receives from the <code>SET_FILTER</code> action payload</li>
                                </ul>
                            </li>
                            <li>Action Types
                                <ul>
                                    <li>We use a file <code>TodoAppConstants</code> to hold action types to be reused</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="margin-top-small">
                    You may check out <a href="https://github.com/chandrakiran-dev/lwc-redux/tree/master/force-app/main/default/lwc/examples/lwc/todo/lwc" target="_blank">Github code</a> for the source code of the all components and the connected Redux store.
                    </div>
                </section>
                
                <section className="docs-section" id="providing-the-store">
                    <h2 className="section-heading">Providing the Store <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'providing-the-store')}/></h2>
                    <p>First we need to make the <code>store</code> available to our app. To do this, we wrap our app with the <code>{'<c-provider/>'}</code> API provided by LWC Redux.</p>
                    <CodePanel id="todoAppHTMLContainer" htmlFileName="todoAppContainer.html" html={todoContainerElementHTML} htmlFirst></CodePanel>
                    <p className="margin-top-small">There are two attributes in the <code>{'<c-provider/>'}</code>. Those names are <code>oninit</code> and <code>store</code>.</p>
                    <p><code>oninit</code> is a method that calls after initialization of the resource and <code>store</code> is a redux object that's initialized in the init method. Refer below code for reference.</p>
                    <CodePanel id="todoAppJSContainer" jsFileName="todoAppContainer.js" js={todoContainerJS} ></CodePanel>
                </section>
                
                <section className="docs-section" id="creating-reducers-function">
                    <h2 className="section-heading">Creating Reducers Functions <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'creating-reducers-function')}/></h2>
                    <p>In the above code, you can see that, to create the store you need to the reducer function. Now, we are going to create a reducers for the store.</p>
                    <p> Reducer is an object that has the collection of function and categorized on the basis of the functionality. </p>
                    <p>Todo app have two reducers. One is <code>todo.js</code> and other one is <code>fiter.js</code>. To make the object and available for global use, we have created a LWC component called <code>todoAppReducer</code>. </p>
                    <CodePanel id="todoReducerJS" jsFileName="todo.js" js={todoReducerJS}></CodePanel>
                    <p className="margin-top-small"><code>todo.js</code> reducer will manage all functionality related to todo's. like <code>ADD_TODO</code> and <code>CHANGE_TODO_STATUS</code></p>
                    <p>In the above code, you can see that reducers function will accept two arguments. One is <code>state</code> that have data related to particular reducer and other one is <code>action</code> that will have action type to perform the action. </p>
                    <p><code>initialState</code> will insure that state will never be <code>undefined</code>.</p>
                    <CodePanel id="filterReducerJS" jsFileName="filter.js" js={filterReducerJS}></CodePanel>
                    <p className="margin-top-small"><code>filter.js</code> reducer will perform functionality to filter the list.</p>
                    <CodePanel id="todoAppReducerJS" jsFileName="todoAppReducer.js" js={todoAppReducerJS}></CodePanel>
                    <p className="margin-top-small"><code>todoAppReducer.js</code> this will create the object and combine both the reducers. This will also assure the accessibility of the reducers.</p>
                </section>

                <section className="docs-section" id="defining-actions">
                    <h2 className="section-heading">Defining Actions <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'defining-actions')}/></h2>
                    <p>Actions are the functions, that always return the action type and the payload. Action type are the constant strings and the payload will be the data, you want to pass for the functionality.</p>
                    <p>Todo app have two action. One is <code>todo.js</code> and other one is <code>fiter.js</code>. To make the object and available for global use, we have created a LWC component called <code>todoAppActions</code>.</p>
                    <CodePanel id="todoReducerJS" jsFileName="todo.js" js={todoActionJS}></CodePanel>
                    <p className="margin-top-small"><code>todo.js</code> actions will return a object that contains <code>type</code> and <code>payload</code>. Action type like <code>ADD_TODO</code> and <code>CHANGE_TODO_STATUS</code></p>
                    <CodePanel id="filterReducerJS" jsFileName="filter.js" js={filterActionJS}></CodePanel>
                    <p className="margin-top-small"><code>filter.js</code> actions will have the action for fliter functionality.</p>
                    <CodePanel id="todoAppReducerJS" jsFileName="todoAppAction.js" js={todoAppActionJS}></CodePanel>
                    <p className="margin-top-small"><code>todoAppAction.js</code> this will create the object and combine both the reducers. This will also assure the accessibility of the reducers.</p>
                </section>

                <section className="docs-section" id="connecting-the-components">
                    <h2 className="section-heading">Connecting the Components <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'connecting-the-components')}/></h2>
                    <p>LWC Redux provides a <code>ReduxElement</code> class for you to read values from the Redux store and perform action to update.</p>
                    <p>The <code>ReduxElement</code> class has two functions, both are optional:</p>
                    <ul>
                        <li><code>mapStateToProps</code>: called every time the store state changes. It receives the entire store state, and should return an object of data this component needs.</li>
                        <li><code>mapDispatchToProps</code>: t’s an object full of action creators, each action creator will be turned into a prop function that automatically dispatches its action when called.</li>
                    </ul>
                    <p><code>addTodo</code> component has a text box and the button to submit the text. In the JS code, you can see that we have extended <code>AddTodo</code> {'class'} to <code>ReduxElement</code>. We have also added the <code>mapDispatchToProps</code> function to make action creator to props.</p>
                    <CodePanel id="addTodoComponent" htmlFileName="addTodo.html" jsFileName="addTodo.js" js={addTodoJS} html={addTodoHTML}></CodePanel>
                    <p className="margin-top-small"><code>todoList</code> component will show the list of todo's. This component has implemented the <code>mapStateToProps</code>.</p>
                    <CodePanel id="todoListComponent" jsFileName="todoList.js" js={todoListJS} htmlFileName="todoList.html" html={todoListHTML} ></CodePanel>
                    <p className="margin-top-small"><code>todo</code> component will show the individual todo. It has action drop down using that you can change the status of the todo.</p>
                    <CodePanel id="todoComponent" jsFileName="todo.js" js={todoJS} htmlFileName="todo.html" html={todoHTML} ></CodePanel>
                    <p className="margin-top-small"><code>todoFilter</code> component will use to filter the todo list.</p>
                    <CodePanel id="todoFilterComponent" jsFileName="todoFilter.js" js={todoFilterJS} htmlFileName="todoFilter.html" html={todoFilterHTML} ></CodePanel>
                </section>


                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton leftLabel="Why use LWC Redux" leftURL="/why-use-lwc-redux" rightLabel="Extracting Data with mapStateToProps" rightURL="/extracting-data-with-mapStateToProps"></NavigationButton>
                </section>
                
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='basic-tutorial' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
        );
    }
}

export default BasicTutorial;