import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {providerJS,providerHTML} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class Provider extends Component{
    state = {rightNav : [
        {
            name : 'Provider',
            id : 'provider'
        },
        {
            name : 'Element Attributes',
            id : 'element-attributes'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading"><code>Provider</code> </h1>
                    <section className="docs-intro">
                        <p>
                            The <code>{'<c-provider/>'}</code> makes the Redux store available to any nested components that have been wrapped in the <code>ReduxElement</code>.
                            <br></br><br></br>
                            Since any LWC-redux component in a application can be connected, most applications will render a <code>{'<c-provider/>'}</code> at the top level, with the entire app’s component tree inside of it.
                            <br></br><br></br>
                            Normally, you can’t use a <code>ReduxElement</code> component unless it is nested inside of a <code>{'<c-provider/>'}</code>
                        </p>
                    </section>
                </header>

                <section className="docs-section" id="provider">
                    <h2 className="section-heading">Provider <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'provider')}/></h2>
                    <p>Provider is a lightning web component, which makes the Redux store available for you application. Your root component should be the child of the <code>{'<c-provider/>'}</code>.</p>
                    <CodePanel id="counterContainer" htmlFileName="counterContainer.html" jsFileName="counterContainer.js" js={providerJS} html={providerHTML} htmlFirst></CodePanel>
                </section>

                <section className="docs-section" id="element-attributes">
                    <h2 className="section-heading">Element Attributes <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'element-attributes')} /></h2>
                    <p><code>store</code> : (<a href="https://redux.js.org/api/store">Redux Store</a>) The single Redux store in your application. </p>
                    <p><code>oninit</code> : This is a method of application class and this method will the store. You can see the example of this on above example js file.</p>
                    <p>Both the attributes are mandatory for the <code>provider</code> element</p>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton leftLabel="Server Interaction" leftURL="/server-interacting" rightLabel="ReduxElement" rightURL="/redux-element" ></NavigationButton>
                </section>
                
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default Provider;