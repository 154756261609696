import React, {Component} from 'react';
import CodePanel from '../../components/codePanel/codePanel';
import NavigationButton from '../../components/navigationButtons/navigationButton';
import {mapStateToPropsJS} from '../../utils/codeSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLink } from '@fortawesome/free-solid-svg-icons';
import RightSidebar from '../../components/rightSidebar/rightSidebar';
import {scrollTo} from '../../utils/helper';

class ExtractingDataWithMapStateToProps extends Component{
    state = {rightNav : [
        {
            name : 'Defining mapStateToProps',
            id : 'defining-mapStateToProps'
        },
        {
            name : 'Usage Guidelines',
            id : 'usage-guidelines'
        },
        {
            name : 'Link and References',
            id : 'link-and-references'
        }
    ]}
    addHashInUrl(event,id){
        event.preventDefault();
        window.location.hash = id;
        scrollTo(id);
        event.stopPropagation()
    }
    render(){
        return(
            <div className="docs-content row">
                <div className="col col-lg-8 page-container">
                <div className="container">
                <article className="docs-article" id="section-1">
                <header className="docs-header">
                    <h1 className="docs-heading">Extracting Data with <code>mapStateToProps</code> </h1>
                    <section className="docs-intro"> 
                        <p>This is first pre-defined method for the <code>ReduxElement</code>,  <code>mapStateToProps</code> is used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short </p>
                        <ul>
                            <li>It is called every time the store state changes.</li>
                            <li>It receives the entire store state, and should return an object of data this component needs.</li>
                        </ul>
                    </section>
                </header>
                <section className="docs-section" id="defining-mapStateToProps">
                    <h2 className="section-heading">Defining <code>mapStateToProps</code> <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'defining-mapStateToProps')} /></h2>
                    <p><code>mapStateToProps</code> should be defined as a function:</p>   
                    <CodePanel id="mapStateToPropsJS" jsFileName="counter.js" js={mapStateToPropsJS}></CodePanel>
                    <p className="margin-top-small">It should take a first argument called state, and return a plain object containing the data that the connected component needs. This function will be called every time when the Redux store state changes. </p>

                    <h3 className="sub-section-heading">Arguments </h3>
                    <p>This method has only one arguments called <code><b>state</b></code>. This variable has the entire Redux store state. </p>
                    <p>The <code>mapStateToProps</code> function should always be written with <code>state</code> passed in.</p>
                
                    <h3 className="sub-section-heading">Return </h3>
                    <p>Your mapStateToProps function should return a plain object that contains the data the component needs:</p>
                    <ul>
                        <li>Each field in the object will become a prop for your actual component.</li>
                        <li>The values in the fields will be used to determine if your component needs to re-render.</li>
                    </ul>
                </section>
                <section className="docs-section" id="usage-guidelines">
                    <h2 className="section-heading">Usage Guidelines <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'usage-guidelines')}/></h2>
                    <h3 className="sub-section-heading">Let <code>mapStateToProps</code> Reshape the Data from the Store </h3>
                    <p><code>mapStateToProps</code> functions can, and should, do a lot more than just return <code>state.someSlice</code>. They have the responsibility of "re-shaping" store data as needed for that component. This may include returning a value as a specific prop name, combining pieces of data from different parts of the state tree, and transforming the store data in different ways.</p>
                    <h3 className="sub-section-heading">mapStateToProps Functions Should Be Fast</h3>
                    <p>Whenever the store changes, all of the mapStateToProps functions of all of the connected components will run. Because of this, your mapStateToProps functions should run as fast as possible. This also means that a slow <code>mapStateToProps</code> function can be a potential bottleneck for your application.</p>
                    <p>As part of the "re-shaping data" idea, mapStateToProps functions frequently need to transform data in various ways (such as filtering an array, mapping an array of IDs to their corresponding objects, or extracting plain JS values from Immutable.js objects). These transformations can often be expensive, both in terms of cost to execute the transformation, and whether the component re-renders as a result. If performance is a concern, ensure that these transformations are only run if the input values have changed.</p>
                    <h3 className="sub-section-heading"><code >mapStateToProps</code> Functions Should Be Pure and Synchronous</h3>
                    <p>Much like a Redux reducer, a mapStateToProps function should always be 100% pure and synchronous. It should only take state (and ownProps) as arguments, and return the data the component needs as props without mutating those arguments. It should not be used to trigger asynchronous behavior like AJAX calls for data fetching, and the functions should not be declared as async.</p>
                </section>

                <section className="docs-section" id="link-and-references">
                    <h2 className="section-heading">Link and References <FontAwesomeIcon icon={faLink} onClick={(e) => this.addHashInUrl(e,'link-and-references')}/></h2>
                    <p>Refer below links to getting started with Redux and Lightning Web Components.</p>
                    <ul>
                        <li><a href="https://redux.js.org/introduction/getting-started#basic-example" target="_blank">Redux Getting Started</a></li>
                        <li><a href="https://trailhead.salesforce.com/en/content/learn/projects/quick-start-lightning-web-components/create-a-hello-world-lightning-web-component" target="_blank">Create a Hello World Lightning Web Component</a></li>
                    </ul>
                </section>
                <section className="docs-section">
                    <NavigationButton leftLabel="Basic Tutorial" leftURL="/basic-tutorial" rightLabel="Dispatching Action with mapDispatchToProps" rightURL="/dispatching-actions-with-mapDispatchToProps" ></NavigationButton>
                </section>
                
            </article>
                </div>
                </div>
                <div className="col col-lg-4 col-right-container">
                    <div className="right-container">
                        <RightSidebar id='quickStart' items={this.state.rightNav}></RightSidebar>
                    </div>
                </div>
 
            </div>
            
        );
    }
}

export default ExtractingDataWithMapStateToProps;