import React, {Component} from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './codePanel.css';
class CodePanel extends Component{
    state = {
        value: '',
        copied: false,
        activeTab: 'html',
        js: this.props.js,
        html:this.props.html
    };
    changeTab(selectedTab){
        this.setState({activeTab: selectedTab, copied: false})
    }
    copy(){
        this.setState({copied: true})
        setTimeout(()=>{
            this.setState({copied: false})
        }, 5000)
    }
    componentWillMount(){
        if(this.props.htmlFirst){
            this.setState({activeTab: 'html'})
        }else{
            this.setState({activeTab: 'js'})
        }
    }
    render(){
        const selectedText = this.state[this.state.activeTab];
        let tabs;
        let content;
        let htmlTab = this.state.html ? (
            <li className="nav-item">
                <a className={"nav-link " + (this.props.htmlFirst ? "active" : "")} id={this.props.id + 'html-tab'} data-toggle="tab" href={'#' + this.props.id + 'html'} role="tab" aria-controls={this.props.id + 'html'}
                aria-selected="true" onClick={() => this.changeTab('html')}>{this.props.htmlFileName}</a>
            </li>
        ) : '';
        let jsTab = this.state.js ? (
            <li className="nav-item">
                <a className={"nav-link " + (this.props.htmlFirst ? "" : "active")} id={this.props.id + 'js-tab'} data-toggle="tab" href={'#' + this.props.id + 'js'} role="tab" aria-controls={this.props.id + 'js'}
                aria-selected="false" onClick={() => this.changeTab('js')}>{this.props.jsFileName}</a>
            </li>
        ) : '';

        let htmlContent = this.state.html ? (
            <div className={"tab-pane fade " + (this.props.htmlFirst ? "show active" : "")} id={this.props.id + 'html'} role="tabpanel" aria-labelledby={this.props.id + 'html-tab'}>
                <SyntaxHighlighter language="html" style={dark}>
                {this.state.html}
                </SyntaxHighlighter>
            </div>
        ) : '' ;

        let jsContent = this.state.js ? (
            <div className={"tab-pane fade " + (this.props.htmlFirst ? "" : "show active")} id={this.props.id + 'js'} role="tabpanel" aria-labelledby={this.props.id + 'js-tab'}>
                <SyntaxHighlighter language="javascript" style={dark}>
                {this.state.js}
                </SyntaxHighlighter>
            </div>
        ) : '';


        if(this.props.htmlFirst){
            tabs = (
                <ul className="nav nav-tabs" id={this.props.id} role="tablist">
                    {htmlTab}
                    {(this.state.js?jsTab:'')}
                </ul>
            );
            content = (
                <div className="tab-content" id={this.props.id + 'Content'}>
                    {htmlContent}
                    {(this.state.js?jsContent:'')}
                </div>
            )
        }else{
            tabs = (
                <ul className="nav nav-tabs" id={this.props.id} role="tablist">
                    {jsTab}
                    {(this.state.html?htmlTab:'')}
                </ul>
            );
            content = (
                <div className="tab-content" id={this.props.id + 'Content'}>
                    {jsContent}
                    {(this.state.html?htmlContent:'')}
                </div>
            )
        }
        return (
            <div className="code-panel">
                {tabs}
                <ul className="nav nav-tabs justify-content-end">
                    <li className="nav-item">
                    <CopyToClipboard text={selectedText} onCopy={() => this.copy()}>
                        <a className="nav-link copy" href="#copy" ><FontAwesomeIcon icon={faCopy} /><span className='copy-margin'>{this.state.copied? 'Copied' : 'Copy'}</span></a>
                    </CopyToClipboard>
                        
                    </li>
                </ul>
                {content}
            </div>
          );
    }
  
}
export default CodePanel;