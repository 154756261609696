import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const NavItemHeader = (props) => {
  return (
    <li className="nav-item section-title">
        <a className="nav-link scrollto active">
            <span className="theme-icon-holder mr-2">
                <FontAwesomeIcon icon={props.icon} /></span>
                {props.title}
        </a>
    </li>
  );
};
export default NavItemHeader;